import { ActionContext } from '../../../cra';
import LandingPageComponent from '../../../component/landing';
import React from 'react';
import Vigil from '@foxsenseinnovations/vigil-sdk-js';

const IndexPage = () => {
  const { trackCurrentPage } = React.useContext(ActionContext);
  React.useEffect(() => {
    trackCurrentPage('MS/X Page');
    Vigil.initialize({
      apiKey: process.env.GATSBY_VIGIL_API_KEY
        ? process.env.GATSBY_VIGIL_API_KEY
        : '',
      clientVersion: process.env.GATSBY_VIGIL_CLIENT_VERSION
        ? process.env.GATSBY_VIGIL_CLIENT_VERSION
        : '',
    });
  }, []);

  return (
    <LandingPageComponent
      isMedicareAdvantage={false}
      isPDP={false}
      isMS={true}
    />
  );
};

export default IndexPage;
